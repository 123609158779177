<template>
  <div id="travelOrderContainer">
    <TravelOrderEdit :travelOrderId="travelOrderId"></TravelOrderEdit>
  </div>
</template>

<script>
import Vue from 'vue';
import TravelOrderEdit from "../../components/Sopotniki/travelOrders/TravelOrderEdit";


export default {
  name: 'Reservation',

  components: {
    TravelOrderEdit
  },

  data() {
    return {
      travelOrderId: this.$route.params.travelOrderId ? parseInt(this.$route.params.travelOrderId) : null,
    }
  },

  computed: {},

  mounted() {
    const _this = this;
    // _this.getReservation();
  },

  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    const _this = this;
    _this.travelOrderId = null;
    setTimeout(() => {next()}, 100);
  },

  methods: {
    // getReservation() {
    //   const _this = this;
    //
    //   _this.$vs.loading();
    // },

  }
}
</script>

