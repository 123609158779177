<template>
  <div id="travelOrderEditContainer" class="relative">

    <div>

      <div class="sm:none md:flex-auto lg:flex xl:flex">

        <div class="sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
          <div class="sm:mr-0 md:mr-0 lg:mr-5 xl:mr-10">

            <div class="vx-row mb-2">
              <div class="vx-col w-full">
                <h1 v-if="travelOrderData && travelOrderData.id">Uredi službeni potni nalog</h1>
                <h1 v-if="!travelOrderData || !travelOrderData.id">Dodaj službeni potni nalog</h1>
              </div>
            </div>

            <div class="vx-row mb-2" v-if="travelOrderTypesData && travelOrderData">

              <div class="vx-col w-full">
                <label class="vs-input--label">
                  Tip
                </label>
                <v-select :filter="fuseSearchName" :options="travelOrderTypesData" class="" v-model="travelOrderType"
                          :placeholder="'Tip'"
                          :reduce="item => item.id"
                          :getOptionLabel="option => option.name">
                  <template v-slot:option="option">
                    <vs-list-item
                      :title="option.name">
                    </vs-list-item>
                  </template>

                  <template v-slot:selected-option="option">
                    <vs-list-item
                      :title="option.name">
                    </vs-list-item>
                  </template>

                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                  </template>
                </v-select>

                <div class="flex mt-1" v-if="$v.travelOrderType.$dirty">
                  <div class="w-full">
                    <vs-alert :active="!$v.travelOrderType.required" :color="'danger'">
                      Polje "Tip" ne sme biti prazno.
                    </vs-alert>
                  </div>
                </div>

              </div>
            </div>

            <div class="vx-row mt-5 mb-2" v-if="volunteersData && travelOrderData">

              <div class="vx-col w-full">
                <label class="vs-input--label">
                  Voznik
                </label>
                <v-select :filter="fuseSearch" :options="volunteersData" class="" v-model="volunteer"
                          placeholder="Voznik"
                          :getOptionLabel="option => option.first_name + '' + option.last_name">
                  <template v-slot:option="option">
                    <vs-list-item
                      :title="option.first_name + ' ' + option.last_name">
                      <template slot="avatar">
                        <div class="avatarWithStatus mr-5">
                          <vs-avatar size="large" :src="$globalFunctions.getUserAvatar(option.profile_image)"/>
                          <vs-avatar v-if="option.active === 1" size="small" class="status" color="success"
                                     icon="check_circle"/>
                          <vs-avatar v-if="option.active === 0" size="small" class="status" color="danger"
                                     icon="radio_button_unchecked"/>
                        </div>
                      </template>
                    </vs-list-item>
                  </template>

                  <template v-slot:selected-option="option">
                    <vs-list-item
                      class="d-block"
                      :title="option.first_name + ' ' + option.last_name">
                      <template slot="avatar">
                        <div class="avatarWithStatus mr-5">
                          <vs-avatar size="large" :src="$globalFunctions.getUserAvatar(option.profile_image)"/>
                          <vs-avatar v-if="option.active === 1" size="small" class="status" color="success"
                                     icon="check_circle"/>
                          <vs-avatar v-if="option.active === 0" size="small" class="status" color="danger"
                                     icon="radio_button_unchecked"/>
                        </div>
                      </template>
                    </vs-list-item>
                  </template>

                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                  </template>
                </v-select>

                <div class="flex mt-1" v-if="$v.volunteer.$dirty">
                  <div class="w-full">
                    <vs-alert :active="!$v.volunteer.required" :color="'danger'">
                      Polje "Voznik" ne sme biti prazno.
                    </vs-alert>
                  </div>
                </div>

              </div>
            </div>

            <div class="vx-row mt-5" v-if="travelOrderData">

              <div class="vx-col w-full">
                <label class="vs-input--label">
                  Delovno mesto voznika
                </label>
                <v-select
                  :options="['prostovoljec', 'koordinator enote', 'koordinator programa', 'vodja programa', 'direktor', 'skrbnik donacij in sponzorstev', 'administrator']"
                  class="" v-model="travelOrderData.working_position"
                  placeholder="Delovno mesto voznika">


                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                  </template>
                </v-select>

                <div class="flex mt-1" v-if="$v.travelOrderData.working_position.$dirty">
                  <div class="w-full">
                    <vs-alert :active="!$v.travelOrderData.working_position.required" :color="'danger'">
                      Polje "Voznik" ne sme biti prazno.
                    </vs-alert>
                  </div>
                </div>

              </div>
            </div>

            <div class="vx-row mt-5" v-if="carsData">

              <div class="vx-col w-full">
                <label class="vs-input--label">
                  Vozilo, s katerim bo prevoz opravljen
                </label>
                <v-select :filter="fuseSearchName" :options="carsData" v-model="car" placeholder="Vozilo"
                          :getOptionLabel="option => option.name">
                  <template v-slot:option="option">
                    <vs-list-item
                      :title="option.name"
                      :subtitle="option.registrationNumber">
                      <template slot="avatar">
                        <vs-avatar size="large" class="mr-5" :style="'background-color:' + option.color"
                                   :text="option.name"/>
                      </template>
                    </vs-list-item>
                  </template>

                  <template v-slot:selected-option="option">
                    <vs-list-item
                      :title="option.name"
                      :subtitle="option.registrationNumber">
                      <template slot="avatar">
                        <vs-avatar size="large" class="mr-5" :style="'background-color:' + option.color"
                                   :text="option.name"/>
                      </template>
                    </vs-list-item>
                  </template>

                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                  </template>
                </v-select>

                <div class="flex mt-1" v-if="$v.car.$dirty">
                  <div class="w-full">
                    <vs-alert :active="!$v.car.required" :color="'danger'">
                      Polje "Vozilo" ne sme biti prazno.
                    </vs-alert>
                  </div>
                </div>

              </div>
            </div>

            <div class="grid grid-cols-2 gap-2 mt-5" v-if="travelOrderData && carsData && car && car.id === -1">
              <div>
                <vs-input class="w-full" label="Naziv vozila" type="text"
                          v-model="travelOrderData.custom_car_name"/>
              </div>
              <div>
                <vs-input class="w-full" label="Registrska oznaka vozila" type="text"
                          v-model="travelOrderData.custom_car_registration_number"/>
              </div>
            </div>


            <div v-if="travelOrderData">
              <div class="grid grid-cols-3 gap-2 mt-5">
                <div>
                  <label class="vs-input--label">Datum službene poti</label>
                  <datepicker placeholder="Datum službene poti" :language="slSI" :format="customDateFormatter"
                              :monday-first="true"
                              @input="onDateChange" v-model="travelOrderData.departure_time"></datepicker>

                  <div class="flex mt-1" v-if="$v.travelOrderData.departure_time.$dirty">
                    <div class="w-full">
                      <vs-alert :active="!$v.travelOrderData.departure_time.required" :color="'danger'">
                        Polje "Datum službene poti" ne sme biti prazno.
                      </vs-alert>
                    </div>
                  </div>

                </div>
                <div>
                  <label class="vs-input--label">Ura odhoda na destinacijo
                    (A
                    <vs-icon icon="keyboard_arrow_right"></vs-icon>
                    B)
                  </label>
                  <flat-pickr :config="configTimePicker" v-model="departureTime" placeholder="Ura odhoda na destinacijo" @input="calculateSubsistenceAllowance"
                              class="w-full"/>

                  <div class="flex mt-1" v-if="$v.departureTime.$dirty">
                    <div class="w-full">
                      <vs-alert :active="!$v.departureTime.required" :color="'danger'">
                        Polje "Ura odhoda na destinacijo" ne sme biti prazno.
                      </vs-alert>
                    </div>
                  </div>
                </div>
                <div>
                  <label class="vs-input--label">Ura odhoda na povratek (B
                    <vs-icon icon="keyboard_arrow_right"></vs-icon>
                    C)
                  </label>
                  <flat-pickr :config="configTimePicker" v-model="arrivalTime" placeholder="Ura odhoda na destinacijo" @input="calculateSubsistenceAllowance"
                              class="w-full"/>

                  <div class="flex mt-1" v-if="$v.arrivalTime.$dirty">
                    <div class="w-full">
                      <vs-alert :active="!$v.arrivalTime.required" :color="'danger'">
                        Polje "Ura odhoda na povratek" ne sme biti prazno.
                      </vs-alert>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex mt-5">
                <div class="w-full">
                  <label class="vs-input--label">Opombe službenega potnega naloga</label>
                  <vs-textarea class="w-full" rows="6" v-model="travelOrderData.notes"/>
                </div>
              </div>

              <div class="vx-card mt-5">
                <div class="vx-card__header">
                  <div class="vx-card__title">
                    <h4 class=""><img class="markerIcon" alt=""
                                      src="data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20width%3D%2227px%22%20height%3D%2243px%22%20viewBox%3D%220%200%2027%2043%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%3Cdefs%3E%0A%3Cpath%20id%3D%22a%22%20d%3D%22m12.5%200c-6.9039%200-12.5%205.5961-12.5%2012.5%200%201.8859%200.54297%203.7461%201.4414%205.4617%203.425%206.6156%2010.216%2013.566%2010.216%2022.195%200%200.46562%200.37734%200.84297%200.84297%200.84297s0.84297-0.37734%200.84297-0.84297c0-8.6289%206.7906-15.58%2010.216-22.195%200.89844-1.7156%201.4414-3.5758%201.4414-5.4617%200-6.9039-5.5961-12.5-12.5-12.5z%22%2F%3E%0A%3C%2Fdefs%3E%0A%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%3Cg%20transform%3D%22translate(1%201)%22%3E%0A%3Cuse%20fill%3D%22%23EA4335%22%20fill-rule%3D%22evenodd%22%20xlink%3Ahref%3D%22%23a%22%2F%3E%0A%3Cpath%20d%3D%22m12.5-0.5c7.18%200%2013%205.82%2013%2013%200%201.8995-0.52398%203.8328-1.4974%205.6916-0.91575%201.7688-1.0177%201.9307-4.169%206.7789-4.2579%206.5508-5.9907%2010.447-5.9907%2015.187%200%200.74177-0.6012%201.343-1.343%201.343s-1.343-0.6012-1.343-1.343c0-4.7396-1.7327-8.6358-5.9907-15.187-3.1512-4.8482-3.2532-5.01-4.1679-6.7768-0.97449-1.8608-1.4985-3.7942-1.4985-5.6937%200-7.18%205.82-13%2013-13z%22%20stroke%3D%22%23fff%22%2F%3E%0A%3C%2Fg%3E%0A%3Ctext%20text-anchor%3D%22middle%22%20dy%3D%220.3em%22%20x%3D%2214%22%20y%3D%2215%22%20font-family%3D%22Roboto%2C%20Arial%2C%20sans-serif%22%20font-size%3D%2216px%22%20fill%3D%22%23FFF%22%3EA%3C%2Ftext%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A"
                                      draggable="false" usemap="#gmimap2" style="width: 27px; height: 43px;">
                      <span class="pl-8">Mesto odhoda</span></h4>
                    <div class="clearfix"></div>

                  </div>
                </div>
                <div class="vx-card__collapsible-content">
                  <div class="vx-card__body">

                    <div class="grid grid-cols-1">
                      <label for="departureAddress" class="vs-input--label">Naslov mesta odhoda</label>

                      <gmap-autocomplete
                        ref="departureAddress"
                        id="departureAddress"
                        @place_changed="(data) => { getAddressData(data, 'departure') }"
                        placeholder="začni vpisovati, nato izberi naslov iz ponujenih možnosti"
                        :value="travelOrderData.departure.quick_search"
                        :options="{componentRestrictions: { country: 'si' }}"
                        :select-first-on-enter="true"
                        class="vs-inputx vs-input--input normal"></gmap-autocomplete>
                    </div>

                    <div class="flex mt-1" v-if="$v.travelOrderData.departure.quick_search.$dirty">
                      <div class="w-full">
                        <vs-alert :active="!$v.travelOrderData.departure.quick_search.required" :color="'danger'">
                          Polje "Naslov mesta odhoda" ne sme biti prazno.
                        </vs-alert>
                      </div>
                    </div>

                    <div class="grid grid-cols-1 mt-5">
                      <vs-input class="w-full" label="Naziv mesta odhoda (ime naselja, vasi oz. ustanove)"
                                v-model="travelOrderData.departure.address_name" @blur="copyAddressName"/>
                    </div>

                    <vs-collapse ref="collapsible" class="mt-5">

                      <vs-collapse-item class="addressCollapse">
                        <div slot="header" class="p-0 text-primary">
                          Podrobnosti naslova mesta odhoda
                        </div>

                        <div class="collapseBody mt-5">

                          <div class="flex mb-4">
                            <div class="w-3/4">
                              <vs-input class="w-full" label="Ulica" v-model="travelOrderData.departure.route"/>

                              <div class="flex mt-1" v-if="$v.travelOrderData.departure.route.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.travelOrderData.departure.route.required" :color="'danger'">
                                    Polje "Ulica" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>
                            </div>
                            <div class="w-1/4 ml-5">
                              <vs-input class="w-full" label="Hišna številka"
                                        v-model="travelOrderData.departure.street_number"/>

                              <div class="flex mt-1" v-if="$v.travelOrderData.departure.street_number.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.travelOrderData.departure.street_number.required"
                                            :color="'danger'">
                                    Polje "Hišna številka" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="flex mb-4">
                            <div class="w-3/4">
                              <vs-input class="w-full" label="Naziv pošte"
                                        v-model="travelOrderData.departure.postal_town"/>

                              <div class="flex mt-1" v-if="$v.travelOrderData.departure.postal_town.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.travelOrderData.departure.postal_town.required"
                                            :color="'danger'">
                                    Polje "Naziv pošte" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                            <div class="w-1/4 ml-5">
                              <vs-input class="w-full" label="Poštna številka"
                                        v-model="travelOrderData.departure.postal_code"/>

                              <div class="flex mt-1" v-if="$v.travelOrderData.departure.postal_code.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.travelOrderData.departure.postal_code.required"
                                            :color="'danger'">
                                    Polje "Poštna številka" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div class="flex mb-4">
                            <div class="w-3/4">
                              <vs-input class="w-full" label="Občina" v-model="travelOrderData.departure.municipality"/>

                              <div class="flex mt-1" v-if="$v.travelOrderData.departure.municipality.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.travelOrderData.departure.municipality.required"
                                            :color="'danger'">
                                    Polje "Občina" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                            <div class="w-1/4 ml-5">
                              <vs-input class="w-full" label="Država" v-model="travelOrderData.departure.country"/>

                              <div class="flex mt-1" v-if="$v.travelOrderData.departure.country.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.travelOrderData.departure.country" :color="'danger'">
                                    Polje "Država" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </vs-collapse-item>
                    </vs-collapse>
                  </div>
                </div>
              </div>

              <div class="vx-card mt-5">
                <div class="vx-card__header">
                  <div class="vx-card__title">
                    <h4 class=""><img class="markerIcon" alt=""
                                      src="data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20width%3D%2227px%22%20height%3D%2243px%22%20viewBox%3D%220%200%2027%2043%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%3Cdefs%3E%0A%3Cpath%20id%3D%22a%22%20d%3D%22m12.5%200c-6.9039%200-12.5%205.5961-12.5%2012.5%200%201.8859%200.54297%203.7461%201.4414%205.4617%203.425%206.6156%2010.216%2013.566%2010.216%2022.195%200%200.46562%200.37734%200.84297%200.84297%200.84297s0.84297-0.37734%200.84297-0.84297c0-8.6289%206.7906-15.58%2010.216-22.195%200.89844-1.7156%201.4414-3.5758%201.4414-5.4617%200-6.9039-5.5961-12.5-12.5-12.5z%22%2F%3E%0A%3C%2Fdefs%3E%0A%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%3Cg%20transform%3D%22translate(1%201)%22%3E%0A%3Cuse%20fill%3D%22%23EA4335%22%20fill-rule%3D%22evenodd%22%20xlink%3Ahref%3D%22%23a%22%2F%3E%0A%3Cpath%20d%3D%22m12.5-0.5c7.18%200%2013%205.82%2013%2013%200%201.8995-0.52398%203.8328-1.4974%205.6916-0.91575%201.7688-1.0177%201.9307-4.169%206.7789-4.2579%206.5508-5.9907%2010.447-5.9907%2015.187%200%200.74177-0.6012%201.343-1.343%201.343s-1.343-0.6012-1.343-1.343c0-4.7396-1.7327-8.6358-5.9907-15.187-3.1512-4.8482-3.2532-5.01-4.1679-6.7768-0.97449-1.8608-1.4985-3.7942-1.4985-5.6937%200-7.18%205.82-13%2013-13z%22%20stroke%3D%22%23fff%22%2F%3E%0A%3C%2Fg%3E%0A%3Ctext%20text-anchor%3D%22middle%22%20dy%3D%220.3em%22%20x%3D%2214%22%20y%3D%2215%22%20font-family%3D%22Roboto%2C%20Arial%2C%20sans-serif%22%20font-size%3D%2216px%22%20fill%3D%22%23FFF%22%3EB%3C%2Ftext%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A"
                                      draggable="false" usemap="#gmimap2" style="width: 27px; height: 43px;">
                      <span class="pl-8">Mesto prihoda</span></h4>
                  </div>
                </div>
                <div class="vx-card__collapsible-content">
                  <div class="vx-card__body">

                    <div class="grid grid-cols-1">
                      <label for="destinationAddress" class="vs-input--label">Naslov mesta prihoda</label>

                      <gmap-autocomplete
                        ref="destinationAddress"
                        id="destinationAddress"
                        @place_changed="(data) => { getAddressData(data, 'destination') }"
                        placeholder="začni vpisovati, nato izberi naslov iz ponujenih možnosti"
                        :value="travelOrderData.destination.quick_search"
                        :select-first-on-enter="true"
                        class="vs-inputx vs-input--input normal"></gmap-autocomplete>

                      <div class="flex mt-1" v-if="$v.travelOrderData.destination.quick_search.$dirty">
                        <div class="w-full">
                          <vs-alert :active="!$v.travelOrderData.destination.quick_search.required" :color="'danger'">
                            Polje "Naslov mesta prihoda" ne sme biti prazno.
                          </vs-alert>
                        </div>
                      </div>

                    </div>

                    <div class="grid grid-cols-1 mt-5">
                      <vs-input class="w-full" label="Naziv mesta destinacije (ime naselja, vasi oz. ustanove)"
                                v-model="travelOrderData.destination.address_name"/>
                    </div>

                    <div class="vx-col w-full mt-5" v-if="travelOrderPurposesData">
                      <label for="destinationAddress" class="vs-input--label">Glavni namen poti</label>
                      <v-select :filter="fuseSearchName" :options="travelOrderPurposesData" class=""
                                v-model="travelOrderPurpose" placeholder="Glavni namen poti"
                                :getOptionLabel="option => option.name">
                        <template v-slot:option="option">
                          <vs-list-item
                            :title="option.name">
                            <template slot="avatar">
                              <vs-avatar size="large" :icon="$globalFunctions.getCustomReportPurposeIcon(option.id)"
                                         class="mr-5"/>
                            </template>
                          </vs-list-item>
                        </template>

                        <template v-slot:selected-option="option">
                          <vs-list-item
                            class="d-block"
                            :title="option.name">
                            <template slot="avatar">
                              <vs-avatar size="large" :icon="$globalFunctions.getCustomReportPurposeIcon(option.id)"
                                         class="mr-5"/>
                            </template>
                          </vs-list-item>
                        </template>

                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                          </template>
                          <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                        </template>
                      </v-select>

                    </div>

                    <vs-collapse ref="collapsible" class="mt-5">

                      <vs-collapse-item class="addressCollapse">
                        <div slot="header" class="p-0 text-primary">
                          Podrobnosti naslova mesta prihoda
                        </div>

                        <div class="collapseBody mt-5">
                          <div class="flex mb-4">
                            <div class="w-3/4">
                              <vs-input class="w-full" label="Ulica" v-model="travelOrderData.destination.route"/>

                              <div class="flex mt-1" v-if="$v.travelOrderData.destination.route.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.travelOrderData.destination.route.required" :color="'danger'">
                                    Polje "Ulica" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>
                            </div>
                            <div class="w-1/4 ml-5">
                              <vs-input class="w-full" label="Hišna številka"
                                        v-model="travelOrderData.destination.street_number"/>

                              <div class="flex mt-1" v-if="$v.travelOrderData.destination.street_number.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.travelOrderData.destination.street_number.required"
                                            :color="'danger'">
                                    Polje "Hišna številka" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div class="flex mb-4">
                            <div class="w-3/4">
                              <vs-input class="w-full" label="Naziv pošte"
                                        v-model="travelOrderData.destination.postal_town"/>

                              <div class="flex mt-1" v-if="$v.travelOrderData.destination.postal_town.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.travelOrderData.destination.postal_town.required"
                                            :color="'danger'">
                                    Polje "Naziv pošte" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                            <div class="w-1/4 ml-5">
                              <vs-input class="w-full" label="Poštna številka"
                                        v-model="travelOrderData.destination.postal_code"/>

                              <div class="flex mt-1" v-if="$v.travelOrderData.destination.postal_code.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.travelOrderData.destination.postal_code.required"
                                            :color="'danger'">
                                    Polje "Poštna številka" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div class="flex mb-4">
                            <div class="w-3/4">
                              <vs-input class="w-full" label="Občina"
                                        v-model="travelOrderData.destination.municipality"/>

                              <div class="flex mt-1" v-if="$v.travelOrderData.destination.municipality.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.travelOrderData.destination.municipality.required"
                                            :color="'danger'">
                                    Polje "Občina" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                            <div class="w-1/4 ml-5">
                              <vs-input class="w-full" label="Država" v-model="travelOrderData.destination.country"/>

                              <div class="flex mt-1" v-if="$v.travelOrderData.destination.country.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.travelOrderData.destination.country" :color="'danger'">
                                    Polje "Država" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </vs-collapse-item>
                    </vs-collapse>
                  </div>
                </div>
              </div>

              <div class="vx-card mt-5">
                <div class="vx-card__header">
                  <div class="vx-card__title">
                    <h4 class="">
                      <img class="markerIcon" alt=""
                           src="data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20width%3D%2227px%22%20height%3D%2243px%22%20viewBox%3D%220%200%2027%2043%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%3Cdefs%3E%0A%3Cpath%20id%3D%22a%22%20d%3D%22m12.5%200c-6.9039%200-12.5%205.5961-12.5%2012.5%200%201.8859%200.54297%203.7461%201.4414%205.4617%203.425%206.6156%2010.216%2013.566%2010.216%2022.195%200%200.46562%200.37734%200.84297%200.84297%200.84297s0.84297-0.37734%200.84297-0.84297c0-8.6289%206.7906-15.58%2010.216-22.195%200.89844-1.7156%201.4414-3.5758%201.4414-5.4617%200-6.9039-5.5961-12.5-12.5-12.5z%22%2F%3E%0A%3C%2Fdefs%3E%0A%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%3Cg%20transform%3D%22translate(1%201)%22%3E%0A%3Cuse%20fill%3D%22%23EA4335%22%20fill-rule%3D%22evenodd%22%20xlink%3Ahref%3D%22%23a%22%2F%3E%0A%3Cpath%20d%3D%22m12.5-0.5c7.18%200%2013%205.82%2013%2013%200%201.8995-0.52398%203.8328-1.4974%205.6916-0.91575%201.7688-1.0177%201.9307-4.169%206.7789-4.2579%206.5508-5.9907%2010.447-5.9907%2015.187%200%200.74177-0.6012%201.343-1.343%201.343s-1.343-0.6012-1.343-1.343c0-4.7396-1.7327-8.6358-5.9907-15.187-3.1512-4.8482-3.2532-5.01-4.1679-6.7768-0.97449-1.8608-1.4985-3.7942-1.4985-5.6937%200-7.18%205.82-13%2013-13z%22%20stroke%3D%22%23fff%22%2F%3E%0A%3C%2Fg%3E%0A%3Ctext%20text-anchor%3D%22middle%22%20dy%3D%220.3em%22%20x%3D%2214%22%20y%3D%2215%22%20font-family%3D%22Roboto%2C%20Arial%2C%20sans-serif%22%20font-size%3D%2216px%22%20fill%3D%22%23FFF%22%3EC%3C%2Ftext%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A"
                           draggable="false" usemap="#gmimap2" style="width: 27px; height: 43px;">
                      <span class="pl-8">Mesto povratka</span></h4>
                  </div>
                </div>
                <div class="vx-card__collapsible-content">
                  <div class="vx-card__body">

                    <div class="grid grid-cols-1">
                      <label for="destinationAddress" class="vs-input--label">Naslov mesta povratka</label>

                      <gmap-autocomplete
                        ref="arrivalAddress"
                        id="arrivalAddress"
                        @place_changed="(data) => { getAddressData(data, 'arrival') }"
                        placeholder="začni vpisovati, nato izberi naslov iz ponujenih možnosti"
                        :value="travelOrderData.arrival.quick_search"
                        :options="{componentRestrictions: { country: 'si' }}"
                        :select-first-on-enter="true"
                        class="vs-inputx vs-input--input normal"></gmap-autocomplete>

                      <div class="flex mt-1" v-if="$v.travelOrderData.arrival.quick_search.$dirty">
                        <div class="w-full">
                          <vs-alert :active="!$v.travelOrderData.arrival.quick_search.required" :color="'danger'">
                            Polje "Naslov mesta povratka" ne sme biti prazno.
                          </vs-alert>
                        </div>
                      </div>

                    </div>

                    <div class="grid grid-cols-1 mt-5">
                      <vs-input class="w-full" label="Naziv mesta povratka (ime naselja, vasi oz. ustanove)"
                                v-model="travelOrderData.arrival.address_name"/>
                    </div>

                    <vs-collapse ref="collapsible" class="mt-5">

                      <vs-collapse-item class="addressCollapse">
                        <div slot="header" class="p-0 text-primary">
                          Podrobnosti naslova mesta prihoda
                        </div>

                        <div class="collapseBody mt-5">
                          <div class="flex mb-4">
                            <div class="w-3/4">
                              <vs-input class="w-full" label="Ulica" v-model="travelOrderData.arrival.route"/>

                              <div class="flex mt-1" v-if="$v.travelOrderData.arrival.route.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.travelOrderData.arrival.route.required" :color="'danger'">
                                    Polje "Ulica" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>
                            </div>
                            <div class="w-1/4 ml-5">
                              <vs-input class="w-full" label="Hišna številka"
                                        v-model="travelOrderData.arrival.street_number"/>

                              <div class="flex mt-1" v-if="$v.travelOrderData.arrival.street_number.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.travelOrderData.arrival.street_number.required"
                                            :color="'danger'">
                                    Polje "Hišna številka" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="flex mb-4">
                            <div class="w-3/4">
                              <vs-input class="w-full" label="Naziv pošte"
                                        v-model="travelOrderData.arrival.postal_town"/>

                              <div class="flex mt-1" v-if="$v.travelOrderData.arrival.postal_town.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.travelOrderData.arrival.postal_town.required"
                                            :color="'danger'">
                                    Polje "Naziv pošte" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                            <div class="w-1/4 ml-5">
                              <vs-input class="w-full" label="Poštna številka"
                                        v-model="travelOrderData.arrival.postal_code"/>

                              <div class="flex mt-1" v-if="$v.travelOrderData.arrival.postal_code.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.travelOrderData.arrival.postal_code.required"
                                            :color="'danger'">
                                    Polje "Poštna številka" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div class="flex mb-4">
                            <div class="w-3/4">
                              <vs-input class="w-full" label="Občina" v-model="travelOrderData.arrival.municipality"/>

                              <div class="flex mt-1" v-if="$v.travelOrderData.departure.municipality.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.travelOrderData.departure.municipality.required"
                                            :color="'danger'">
                                    Polje "Občina" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                            <div class="w-1/4 ml-5">
                              <vs-input class="w-full" label="Država" v-model="travelOrderData.arrival.country"/>

                              <div class="flex mt-1" v-if="$v.travelOrderData.arrival.country.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.travelOrderData.arrival.country" :color="'danger'">
                                    Polje "Država" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </vs-collapse-item>
                    </vs-collapse>
                  </div>
                </div>
              </div>
            </div>


            <div class="vx-card mt-5">
              <div class="vx-card__header">
                <div class="vx-card__title">
                  <h4 class="">
                    Izplačilo potnih stroškov</h4>
                </div>
              </div>
              <div class="vx-card__collapsible-content" v-if="travelOrderData">
                <div class="vx-card__body">

                  <div class="grid grid-cols-1 mt-5">
                    <label for="travelOrder_coordinated" class="vs-input--label">Izplačilo potnih stroškov</label>
                    <vs-switch color="success" v-model="travelOrderData.costs_payment"
                               id="travelOrder_coordinated" @change="() => {setMapsDirections();}">
                      <span slot="1">DA</span>
                      <span slot="0">NE</span>
                    </vs-switch>
                  </div>

                  <div v-if="travelOrderData.costs_payment">

                    <vs-tabs class="mt-5" v-model="cost_per_km_factor">
                      <vs-tab label="Prihod na delo - 0,21 eur/km" key="0"
                              @click="() => { travelOrderData.cost_per_km = '0,21'; setMapsDirections();}">
                      </vs-tab>
                      <vs-tab label="Kilometrina - 0,43 eur/km" key="1"
                              @click="() => { travelOrderData.cost_per_km = '0,43'; setMapsDirections();}">
                      </vs-tab>
                    </vs-tabs>

                    <vs-input icon="euro_symbol" class="w-full" label="Znesek za izplačilo"
                              v-model="travelOrderData.route_cost" disabled=""/>
                  </div>
                </div>

              </div>
            </div>

            <div class="vx-card mt-5" v-if="travelOrderData && travelOrderData.departure_time && arrivalTime && departureTime">
              <div class="vx-card__header">
                <div class="vx-card__title">
                  <h4 class="">
                    Izplačilo dnevnice</h4>
                </div>
              </div>
              <div class="vx-card__collapsible-content" v-if="travelOrderData">
                <div class="vx-card__body">

                  <div class="grid grid-cols-1 mt-5">
                    <label for="travelOrder_coordinated" class="vs-input--label">Izplačilo dnevnice</label>
                    <vs-switch color="success" v-model="travelOrderData.subsistenceAllowance" @change="calculateSubsistenceAllowance"
                               id="travelOrder_coordinated">
                      <span slot="1">DA</span>
                      <span slot="0">NE</span>
                    </vs-switch>
                  </div>

                  <div v-if="travelOrderData.subsistenceAllowance">

                    <vs-input icon="access_time" class="w-full mt-5" label="Čas odsotnosti z delovnega mesta (čas službene poti)"
                              v-model="travelOrderData.subsistenceAllowanceDuration" disabled=""/>

                    <vs-input icon="euro_symbol" class="w-full mt-5" label="Znesek dnevnice za izplačilo"
                              v-model="travelOrderData.subsistenceAllowanceAmount" disabled=""/>

                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
        <div class="sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
          <template>

            <div class="vx-card mt-16" style="overflow: hidden">
              <div class="vx-card__header">
                <h4 class="text-success mb-4">Načrt poti</h4>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body p-0">

                  <div
                    v-if="travelOrderData && travelOrderData.departure.quick_search && travelOrderData.destination.quick_search">
                    <vs-collapse class="p-0">
                      <vs-collapse-item>
                        <div slot="header">
                          <vs-list-item v-if="routeDistanceTotal" title="Skupna razdalja"
                                        :subtitle="routeDistanceTotal + ' km'">
                            <template slot="avatar">
                              <vs-avatar text="skupaj"/>
                            </template>
                          </vs-list-item>
                        </div>

                        <vs-list-item v-if="routeDistanceAB" title="Razdalja od A do B"
                                      :subtitle="parseFloat(routeDistanceAB/1000).toFixed(2).toString() + ' km'">
                          <template slot="avatar">
                            <vs-avatar text="AB"/>
                          </template>
                        </vs-list-item>
                        <vs-list-item v-if="routeDistanceBC" title="Razdalja od B do C"
                                      :subtitle="parseFloat(routeDistanceBC/1000).toFixed(2).toString() + ' km'">
                          <template slot="avatar">
                            <vs-avatar text="BC"/>
                          </template>
                        </vs-list-item>


                      </vs-collapse-item>

                      <vs-collapse-item>
                        <div slot="header">
                          <vs-list-item v-if="routeTravelTimeTotal" title="Skupni čas potovanja"
                                        :subtitle="$globalFunctions.formatSecondsToReadableDuration(routeTravelTimeTotal)">
                            <template slot="avatar">
                              <vs-avatar text="skupaj"/>
                            </template>
                          </vs-list-item>
                        </div>

                        <vs-list-item v-if="routeTravelTimeAB" title="Čas potovanja od A do B"
                                      :subtitle="$globalFunctions.formatSecondsToReadableDuration(routeTravelTimeAB)">
                          <template slot="avatar">
                            <vs-avatar text="AB"/>
                          </template>
                        </vs-list-item>
                        <vs-list-item v-if="routeTravelTimeBC" title="Čas potovanja od B do C"
                                      :subtitle="$globalFunctions.formatSecondsToReadableDuration(routeTravelTimeBC)">
                          <template slot="avatar">
                            <vs-avatar text="BC"/>
                          </template>
                        </vs-list-item>

                      </vs-collapse-item>
                    </vs-collapse>
                  </div>

                  <gmap-map ref="map" :center="center" :zoom="15" style="width: 100%; height: 500px">
                    <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
                                      @closeclick="infoWinOpen=false">

                    </gmap-info-window>
                    <DirectionsRenderer/>
                  </gmap-map>

                </div>
              </div>
            </div>

          </template>
        </div>
      </div>


      <div class="flex mt-10 mb-20" v-if="travelOrderData">
        <div class="w-1/2">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
            <vs-button type="border" icon="cancel" @click="$router.go(-1)" class="mt-2">Prekliči</vs-button>
          </vs-col>
        </div>
        <div class="w-1/2">
          <div class="flex">
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" v-if="travelOrderData.id">
              <vs-button size="large" color="danger" @click="beforeDeleteTravelOrder" icon="delete">Izbriši</vs-button>
            </vs-col>

            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
              <vs-button size="large" color="success" icon="save" @click="saveTravelOrder(false)">Shrani</vs-button>
            </vs-col>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import DirectionsRenderer from "../../../helpers/DirectionsRenderer";
import vSelect from "vue-select";
import Fuse from 'fuse.js'
import Datepicker from 'vuejs-datepicker';
import {slSI} from 'vuejs-datepicker/dist/locale';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import sir from '../../../assets/images/portrait/sir.svg';
import madam from '../../../assets/images/portrait/madam.svg';
import {required} from "vuelidate/lib/validators";

let directionsService = null;
let directionsDisplay = null;
let geoCoder = null;

export default {
  name: 'travelOrder',

  components: {
    DirectionsRenderer,
    'v-select': vSelect,
    'datepicker': Datepicker,
    'flat-pickr': flatPickr,
  },
  props: {
    travelOrderId: Number
  },
  data() {
    return {
      sir: sir,
      madam: madam,
      slSI: slSI,

      // travelOrderData: null,
      routeDistanceAB: null,
      routeDistanceBC: null,
      routeDistanceTotal: null,
      routeTravelTimeAB: null,
      routeTravelTimeBC: null,
      routeTravelTimeTotal: null,
      center: {lat: 46.0569, lng: 14.5058},
      infoContent: '',
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {width: 0, height: -35}
      },

      cost_per_km_factor: 1,

      travelOrderTypesData: null,
      travelOrderType: null,

      customersData: null,
      customer: null,

      volunteersData: null,
      volunteer: null,

      travelOrderPurposesData: null,
      travelOrderPurpose: null,

      carsData: this.$globalFunctions.getCurrentUnit().cars,
      car: null,

      date: new Date(),
      departureTime: this.moment().hours('08').minutes('0').valueOf(),
      arrivalTime: this.moment().hours('10').minutes('0').valueOf(),

      configTimePicker: {
        enableTime: true,
        noCalendar: true,
        allowInput: true,
        altFormat: 'H:i',
        altInput: true,
        time_24hr: true,
        dateFormat: 'H:i',
        minuteIncrement: 15
      },

      travelOrderData: {
        arrival: {},
        arrival_address_id: null,
        arrival_time: null,
        car: {},
        car_id: null,
        cost_per_km: "0,43",
        costs_payment: null,
        custom_car_name: null,
        custom_car_registration_number: null,
        date: null,
        departure: {},
        departure_address_id: null,
        departure_time: null,
        destination: {},
        destination_address_id: null,
        destination_time: null,
        id: null,
        notes: null,
        route_cost: null,
        route_distance: null,
        route_time: null,
        submitted: null,
        type: {},
        unit: {},
        unit_id: null,
        volunteer: {},
        volunteers_id: null,
        working_position: null
      }
    }
  },

  validations: {
    travelOrderType: {
      required
    },
    volunteer: {
      required
    },
    car: {
      required
    },
    departureTime: {
      required
    },
    arrivalTime: {
      required
    },
    travelOrderData: {
      working_position: {
        required
      },
      departure_time: {
        required
      },
      departure: {
        quick_search: {
          required
        },
        route: {
          required
        },
        street_number: {
          required
        },
        postal_code: {
          required
        },
        postal_town: {
          required
        },
        municipality: {
          required
        },
        country: {
          required
        }
      },
      destination: {
        quick_search: {
          required
        },
        route: {
          required
        },
        street_number: {
          required
        },
        postal_code: {
          required
        },
        postal_town: {
          required
        },
        municipality: {
          required
        },
        country: {
          required
        }
      },
      arrival: {
        quick_search: {
          required
        },
        route: {
          required
        },
        street_number: {
          required
        },
        postal_code: {
          required
        },
        postal_town: {
          required
        },
        municipality: {
          required
        },
        country: {
          required
        }
      }
    }
  },

  computed: {},

  mounted() {
    const _this = this;

    _this.carsData.push({id: -1, name: 'drugo vozilo', registrationNumber: 'ročni vnos'});

    _this.$vs.loading();

    let p1 = _this.getTravelOrderTypes();
    let p2 = _this.getVolunteers();

    Promise.all([p1, p2]).then(() => {
      if (_this.travelOrderId) {
        _this.getCustomReport().then(() => {

          _this.$gmapApiPromiseLazy().then(() => {

            directionsService = new google.maps.DirectionsService;
            directionsDisplay = new google.maps.DirectionsRenderer;
            directionsDisplay.setMap(_this.$refs.map.$mapObject);
            geoCoder = new google.maps.Geocoder();
            geoCoder.geocode({'address': this.$globalFunctions.getCurrentUnit().locationForContracts + ', Slovenija'}, function (results, status) {
              if (status === 'OK') {
                _this.center = results[0].geometry.location;
              } else {
                console.error('Geocode was not successful for the following reason: ' + status);
              }
            });

            _this.$vs.loading.close();

            if (_this.$route.params.createdViaDuplicateMethod) {
              Vue.$confirm({
                title: 'Službeni potni nalog je bil ustvarjena preko funkcionalnosti \'Podvoji\'!',
                message: 'Sedaj urejaš novo kopijo. Preveri pravilnost podatkov!',
                button: {
                  yes: 'OK',
                },
                callback: confirm => {

                }
              });
            }

          });

        });
      } else {
        _this.$gmapApiPromiseLazy().then(() => {

          directionsService = new google.maps.DirectionsService;
          directionsDisplay = new google.maps.DirectionsRenderer;
          directionsDisplay.setMap(_this.$refs.map.$mapObject);
          geoCoder = new google.maps.Geocoder();
          geoCoder.geocode({'address': this.$globalFunctions.getCurrentUnit().locationForContracts + ', Slovenija'}, function (results, status) {
            if (status === 'OK') {
              _this.center = results[0].geometry.location;
            } else {
              console.error('Geocode was not successful for the following reason: ' + status);
            }
          });

          _this.$vs.loading.close();

        });


      }
    });
  },

  watch: {
    travelOrderId: {
      handler(val, oldVal) {
        console.log("CHANGE!!!!");
        console.log("val", val);
        this.travelOrderData = null;
        this.routeDistanceAB = null;
        this.routeDistanceBC = null;
        this.routeDistanceTotal = null;
        this.routeTravelTimeAB = null;
        this.routeTravelTimeBC = null;
        this.routeTravelTimeTotal = null;

        if (val) {
          this.getCustomReport();
        }
      }
    }
  },

  methods: {
    async getTravelOrderTypes() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'v2/customReportTypes')
        .then((res) => {
          _this.travelOrderTypesData = res.data.data;
          console.log("travelOrderTypesData: ", res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },
    async getVolunteers() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'volunteers/unit/' + _this.$globalFunctions.getCurrentUnit().id)
        .then((res) => {
          _this.volunteersData = res.data.data;
          console.log("volunteersData: ", res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    async getCustomReport() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'v2/customReport/' + _this.travelOrderId)
        .then((res) => {

          _this.travelOrderData = res.data.data;
          _this.cost_per_km_factor = res.data.data.cost_per_km === '0,43' ? 1 : 0;
          _this.volunteer = _.filter(_this.volunteersData, {id: res.data.data.volunteers_id})[0];
          _this.travelOrderType = res.data.data.type ? res.data.data.type.id : null;
          _this.car = _.filter(_this.carsData, {id: res.data.data.car_id})[0];
          _this.departureTime = res.data.data.departure_time;
          _this.arrivalTime = res.data.data.arrival_time;
          console.log("travelOrderData: ", res.data.data);


          setTimeout(function () {
            directionsService = new google.maps.DirectionsService;
            directionsDisplay = new google.maps.DirectionsRenderer;
            directionsDisplay.setMap(_this.$refs.map.$mapObject);

            _this.setMapsDirections();

          }, 500)


        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    saveTravelOrder(deleted) {
      const _this = this;

      let travelOrder = _.omit(_this.travelOrderData, ['disabled', 'deleted', 'created_at', 'updated_at', 'customer', 'unit', 'volunteer', 'purpose']);
      travelOrder.date = _this.moment(_this.travelOrderData.departure_time).valueOf();

      let departureDate = _this.moment(_this.travelOrderData.departure_time).get('date');
      let departureMonth = _this.moment(_this.travelOrderData.departure_time).get('month');
      let departureYear = _this.moment(_this.travelOrderData.departure_time).get('year');
      let departureHour = (_this.departureTime && _this.departureTime.toString().indexOf(':') > -1) ? _this.departureTime.split(':')[0] : _this.moment(_this.departureTime).format('HH');
      let departureMinute = (_this.departureTime && _this.departureTime.toString().indexOf(':') > -1) ? _this.departureTime.split(':')[1] : _this.moment(_this.departureTime).format('mm');
      travelOrder.departure_time = _this.moment().set({
        'year': departureYear,
        'month': departureMonth,
        'date': departureDate,
        'hour': departureHour,
        'minute': departureMinute
      }).valueOf();


      let arrivalDate = _this.moment(_this.travelOrderData.departure_time).get('date');
      let arrivalMonth = _this.moment(_this.travelOrderData.departure_time).get('month');
      let arrivalYear = _this.moment(_this.travelOrderData.departure_time).get('year');
      let arrivalHour = (_this.arrivalTime && _this.arrivalTime.toString().indexOf(':') > -1) ? _this.arrivalTime.split(':')[0] : _this.moment(_this.arrivalTime).format('HH');
      let arrivalMinute = (_this.arrivalTime && _this.arrivalTime.toString().indexOf(':') > -1) ? _this.arrivalTime.split(':')[1] : _this.moment(_this.arrivalTime).format('mm');
      travelOrder.arrival_time = _this.moment().set({
        'year': arrivalYear,
        'month': arrivalMonth,
        'date': arrivalDate,
        'hour': arrivalHour,
        'minute': arrivalMinute
      }).valueOf();

      this.$v.$touch();
      this.$nextTick(() => this.focusFirstStatus());
      if (this.$v.$invalid) {
        // alert("Preverite, ali so vsi podatki pravilno izpolnjeni!")
        console.log("this.$v.", this.$v);
        return;
      }

      travelOrder.unit_id = _this.$globalFunctions.getCurrentUnit().id;
      travelOrder.volunteers_id = _this.volunteer ? _this.volunteer.id : null;
      travelOrder.car_id = _this.car.id;
      travelOrder.type = _this.travelOrderType;
      travelOrder.route_time = _this.routeTravelTimeTotal;
      travelOrder.route_distance = _this.routeDistanceTotal;

      if (deleted) {
        travelOrder.deleted = true;
      }


      _this.$vs.loading();

      Vue.prototype.$http.post(Vue.prototype.$config.api.productionOld + 'v2/customReport/', travelOrder)
        .then((res) => {

          console.log("res", res);
          _this.$vs.notify({
            color: 'success',
            title: 'Službeni potni nalog uspešno shranjen!',
            text: '',
            position: 'top-right'
          });
          _this.$router.push({name: "Sluzbeni potni nalogi"});
          _this.$vs.loading.close();

        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });

      console.log(JSON.stringify(this.travelOrderData));
      console.log(travelOrder);

    },


    setMapsDirections() {
      const _this = this;

      if (!_this.travelOrderData.departure.quick_search || !_this.travelOrderData.destination.quick_search || !_this.travelOrderData.arrival.quick_search) {
        return;
      }

      directionsService.route({
        origin: _this.travelOrderData.departure.quick_search,
        waypoints: [{
          location: _this.travelOrderData.destination.quick_search,
          stopover: true
        }],
        destination: _this.travelOrderData.arrival.quick_search,
        optimizeWaypoints: false,
        travelMode: google.maps.TravelMode.DRIVING
      }, function (response, status) {
        if (status === 'OK') {
          console.log("response --->", response.routes);
          _this.routeDistanceAB = response.routes[0].legs[0].distance.value;
          _this.routeDistanceBC = response.routes[0].legs[1].distance.value;
          _this.routeDistanceTotal = parseFloat((response.routes[0].legs[0].distance.value + response.routes[0].legs[1].distance.value) * 0.001).toFixed(2);


          // if(_this.travelOrderData.costs_payment === 1) {
          _this.travelOrderData.route_cost = parseFloat(_this.routeDistanceTotal * _this.travelOrderData.cost_per_km.replace(',', '.')).toFixed(2);
          // }


          console.log("routeDistanceTotal", _this.routeDistanceTotal);
          console.log("route_cost", _this.travelOrderData.route_cost);


          _this.routeTravelTimeAB = response.routes[0].legs[0].duration.value;
          _this.routeTravelTimeBC = response.routes[0].legs[1].duration.value;
          _this.routeTravelTimeTotal = response.routes[0].legs[0].duration.value + response.routes[0].legs[1].duration.value;
          directionsDisplay.setDirections(response);
        } else {
          console.error('Directions request failed due to ' + status);
        }
      });
    },

    getAddressData(addressData, addressSection) {
      const _this = this;

      console.log("addressData", addressData);
      console.log("addressSection", addressSection);

      document.getElementById(addressSection + "Address").value = addressData.formatted_address;
      _this.travelOrderData[addressSection].quick_search = addressData.formatted_address;
      _this.travelOrderData[addressSection].route = addressData.address_components[1].long_name;
      _this.travelOrderData[addressSection].street_number = addressData.address_components[0].long_name;
      _this.travelOrderData[addressSection].postal_town = addressData.address_components[2].long_name;
      _this.travelOrderData[addressSection].postal_code = addressData.address_components[5].long_name;
      _this.travelOrderData[addressSection].municipality = addressData.address_components[3].long_name;
      _this.travelOrderData[addressSection].country = addressData.address_components[4].long_name;

      // copy departure data to arrival section if not populated yet
      if (addressSection === 'departure' && _.isEmpty(_this.travelOrderData['arrival'])) {
        console.log("copy address");
        _this.travelOrderData['arrival'] = _.cloneDeep(_this.travelOrderData[addressSection]);
      }

      _this.$forceUpdate();
      _this.setMapsDirections();
    },

    copyAddressName() {
      const _this = this;

      if (!_this.travelOrderData.arrival.address_name || _this.travelOrderData.arrival.address_name === '') {
        _this.travelOrderData.arrival.address_name = _this.travelOrderData.departure.address_name;
      }
    },

    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["first_name", "last_name", "addresses.quick_search"],
        minMatchCharLength: 2,
        shouldSort: true
      });
      return search.length
        ? fuse.search(search).map(({item}) => item)
        : fuse.list;
    },

    fuseSearchName(options, search) {
      const fuse = new Fuse(options, {
        keys: ["name"],
        minMatchCharLength: 2,
        shouldSort: true
      });
      return search.length
        ? fuse.search(search).map(({item}) => item)
        : fuse.list;
    },

    customDateFormatter(date) {
      return this.moment(date).format('DD. MM. YYYY');
    },

    onDateChange() {
      const _this = this;
      //_this.$vs.loading();

      // _this.getVolunteers().then(() => {
      //   _this.getDailyReports().then(() => {
      //     _this.$vs.loading.close();
      //   });
      // });


    },

    beforeDeleteTravelOrder() {
      const _this = this;

      Vue.$confirm({
        title: 'Ste prepričani, da želite izbrisati potni nalog za voznika ' + _this.travelOrderData.volunteer.first_name + ' ' + _this.travelOrderData.volunteer.last_name + ' ?',
        message: 'Po potrditvi bo službeni potni nalog trajno izbrisan.',
        button: {
          yes: 'Izbriši',
          no: 'Prekliči'
        },
        callback: confirm => {
          console.log("confirm", confirm);
          if (confirm) {
            _this.saveTravelOrder(true);
            //_this.deleteTravelOrder();
          }
        }
      });
    },

    deleteTravelOrder() {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.delete(Vue.prototype.$config.api.productionOld + 'travelOrders/' + _this.travelOrderId)
        .then((res) => {

          _this.$router.go(-1);

        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    focusFirstStatus() {
      let domRect = document.querySelector('.vs-alert') ? document.querySelector('.vs-alert').getBoundingClientRect() : null;
      if (domRect) {
        window.scrollTo(
          domRect.left + document.documentElement.scrollLeft,
          domRect.top + document.documentElement.scrollTop - 200, 'smooth'
        );
      }
    },

    calculateSubsistenceAllowance() {
      const _this = this;
      console.log("travelOrderData.subsistenceAllowance", this.travelOrderData.subsistenceAllowance);
      if(this.travelOrderData.subsistenceAllowance) {


        let departureDate = _this.moment(_this.travelOrderData.departure_time).get('date');
        let departureMonth = _this.moment(_this.travelOrderData.departure_time).get('month');
        let departureYear = _this.moment(_this.travelOrderData.departure_time).get('year');
        let departureHour = (_this.departureTime && _this.departureTime.toString().indexOf(':') > -1) ? _this.departureTime.split(':')[0] : _this.moment(_this.departureTime).format('HH');
        let departureMinute = (_this.departureTime && _this.departureTime.toString().indexOf(':') > -1) ? _this.departureTime.split(':')[1] : _this.moment(_this.departureTime).format('mm');
        let startTime = _this.moment().set({
          'year': departureYear,
          'month': departureMonth,
          'date': departureDate,
          'hour': departureHour,
          'minute': departureMinute
        }).valueOf();


        let arrivalDate = _this.moment(_this.travelOrderData.departure_time).get('date');
        let arrivalMonth = _this.moment(_this.travelOrderData.departure_time).get('month');
        let arrivalYear = _this.moment(_this.travelOrderData.departure_time).get('year');
        let arrivalHour = (_this.arrivalTime && _this.arrivalTime.toString().indexOf(':') > -1) ? _this.arrivalTime.split(':')[0] : _this.moment(_this.arrivalTime).format('HH');
        let arrivalMinute = (_this.arrivalTime && _this.arrivalTime.toString().indexOf(':') > -1) ? _this.arrivalTime.split(':')[1] : _this.moment(_this.arrivalTime).format('mm');
        let endTime = _this.moment().set({
          'year': arrivalYear,
          'month': arrivalMonth,
          'date': arrivalDate,
          'hour': arrivalHour,
          'minute': arrivalMinute
        }).valueOf();

        console.log("startTime", startTime);
        console.log("endTime", endTime);

        this.travelOrderData.subsistenceAllowanceDuration = parseFloat(this.moment.duration(this.moment(endTime).diff(this.moment(startTime))).asHours()).toFixed(1);
        this.travelOrderData.subsistenceAllowanceAmount = 0;

        if(this.travelOrderData.subsistenceAllowanceDuration) {
          if(this.travelOrderData.subsistenceAllowanceDuration >= 6 && this.travelOrderData.subsistenceAllowanceDuration < 8) {
            this.travelOrderData.subsistenceAllowanceAmount = 9.69;
          } else if(this.travelOrderData.subsistenceAllowanceDuration >= 8 && this.travelOrderData.subsistenceAllowanceDuration < 12) {
            this.travelOrderData.subsistenceAllowanceAmount = 13.88;
          } else if(this.travelOrderData.subsistenceAllowanceDuration >= 12) {
            this.travelOrderData.subsistenceAllowanceAmount = 27.81;
          }
        }
      } else {
        this.travelOrderData.subsistenceAllowanceAmount = 0;
        this.travelOrderData.subsistenceAllowanceDuration = 0;
      }
    }
  }
}
</script>

